<template>
  <div>
    <common-header></common-header>
    <div class="warp">
      <div class="sarch-big-box">
        <div class="search-box">
          <input class="search-input" type="text" placeholder="搜索商品" v-model="searchVal">
          <i class="icon-search-fdj"></i>
        </div>
        <div class="search-btn jb-r-l" @click="btnSearch">搜索</div>
      </div>
      <ul class="search-res">
        <li class="item" v-for="(item, index) in searchDa.list" :key="index" @click="$router.push(`/shopping/details?id=${item.id}`)">
          <img class="goods-img" :src="getGoodsImg(item)" alt="">
          <div class="content">
            <div class="name">{{item.name}}</div>
            <div class="ms">{{item.description}}</div>
            <div class="price-box">
              <div class="price">
                <span class="unit">￥</span>{{item.unitPrice}}
              </div>
              <!-- <div class="icon-car-btn"></div> -->
            </div>
          </div>
        </li>
      </ul>
      <no-data v-if="nodata"></no-data>
      <!-- 热门搜索 start -->
      <!-- <div class="search-his">
        <div class="title">热门搜索</div>
        <ul class="his-list">
          <li class="item">茅台1499</li>
          <li class="item">茅台1499</li>
        </ul>
      </div> -->
      <!-- 热门搜索 end -->

      <!-- 热门搜索 start -->
      <!-- <div class="search-his">
        <div class="title">历史搜索</div>
        <ul class="his-list">
          <li class="item">茅台1499</li>
          <li class="item">茅台1499</li>
        </ul>
      </div> -->
      <!-- 热门搜索 end -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, getGoodsImg, CountDown  } from "@/utils/common";
export default {
  data() {
    this.getGoodsImg = v => getGoodsImg(v);
    return {
      searchVal:'',
      searchDa: [],
      nodata: false,
    }
  },
  mounted() {

  },
  methods: {
    btnSearch() {
      if (this.searchVal == "") {
        this.$toast("请输入商品名称")
      } else {
        this.GetCommodityList({
          name: this.searchVal,
        }).then((res)=> { 
          this.searchDa = res.data;
          this.nodata = res.data.total > 0 ? false : true;
        })
      }
    },
    ...mapActions('shopping', [
      'GetCommodityList',
    ])
  },
}
</script>
<style lang='less' scoped>
.price-box{
  display: flex;
  justify-content: space-between;
  .price{
    display: flex;
    color: #ff7241;
    font-size: 0.32rem;
    align-items: center;
    font-weight: bold;
    .unit{
      font-size: 0.24rem;
    }
  }
}
.search-res{
  margin-top: .2rem;
  .content{
    flex: 1;
    .name{
      font-size: 0.3rem;
      color: #262626;
      font-weight: bold;
    }
    .ms{
      font-size: 0.26rem;
      color: #bfbfbf;
      margin-top: 0.1rem;
      margin-bottom: 0.25rem;
    }
  }
  .item{
    background: #fff;
    border-radius: 0.1rem;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.15rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }
  .goods-img{
    position: relative;
    width: 1.52rem;
    height: 1.52rem;
    border-radius: 0.05rem;
    margin-right: 0.2rem;
  }
}
.his-list{
  margin-top: .4rem;
  display: flex;
  flex-wrap: wrap;
  .item{
    margin-right: .2rem;
    font-size: .26rem;
    color: #595959;
    background: #f8f8f8;
    padding: .12rem .32rem;
    border-radius: .25rem;
    margin-bottom: .2rem;
  }
}
.search-his{
  margin-top: .5rem;
  .title{
    font-size: .3rem;
    font-weight: bold;
  }
}
.sarch-big-box{
  display: flex;
  padding-top: .2rem;
  .search-btn{
    color: #fff;
    min-width: 1.2rem;
    margin-left: .2rem;
    font-size: .28rem;
    text-align: center;
    line-height: .68rem;
    border-radius: 0.3rem;
  }
}
.search-box{
  width: 100%;
  height: .68rem;
  background: #fff;
  border-radius: .3rem;
  position: relative;
  .icon-search-fdj{
    position: absolute;
    top: 50%;
    left: .2rem;
    transform: translateY(-50%);
  }
  .search-input{
    width: 100%;
    height: 100%;
    font-size: .26rem;
    text-indent: .6rem;
  }
}
</style>